$(document).ready(function () {

    //SLICK SLIDERS
    $('.quote__slider').slick({
        slidesToShow: 1,
        rows: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        infinite: true,
        fade: true,
        cssEase: 'linear',
        responsive:[{ breakpoint: 991.98,settings: {slidesToShow: 1} }]
    });
    $('.col-md-4 .case__list .case__slider').slick({slidesToShow: 1});
    $('.case__slider').slick({
        slidesToShow: 3,
        autoplay: false,
        responsive:[{
            breakpoint: 991.98,
            settings: {
                slidesToShow: 1,
                autoplay: true
            }
        }]
    });
    $('.case__raster').slick({slidesToShow: 4, rows: 2, autoplay: true, infinite: true, cssEase: 'linear', responsive:[{ breakpoint: 991.98,settings: {slidesToShow: 2} }]});
    $('.customer__raster').slick({
        slidesToShow: 6,
        slidesToScroll: 1,
        rows: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        // speed:7500,
        infinite: true,
        cssEase: 'linear',
        responsive:[{ breakpoint: 769,settings: {slidesToShow: 2} },{ breakpoint: 1200,settings: {slidesToShow: 3} },{ breakpoint: 1400,settings: {slidesToShow: 5} } ],
    });

    $('.customer__raster-rows').slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        rows: 2,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        // speed:7500,
        infinite: true,
        cssEase: 'linear',
        responsive:[{
                breakpoint: 769,
                settings: {
                    slidesToShow: 2
                }
            },{
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3
                }
            },{
            breakpoint: 1400,
                settings: {
                slidesToShow: 4
            }
        }],
    });

    $('.pizza__prizes').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: false,
        pauseOnHover: true,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        cssEase: 'linear',
        arrows: false,
        dots: true,
        centerMode: false,
    });

    $('.slickslide__item').slick({
        centerMode: true,
        centerPadding: '300px',
        slidesToShow: 1,
        focusOnSelect: true,
        cssEase: 'linear',
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 4000,
        adaptiveHeight: false,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1430,
                settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '290px',
                    slidesToShow: 1,
                    dots: true
                }
            },
            {
                breakpoint: 1226,
                settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '250px',
                    slidesToShow: 1,
                    dots: true
                }
            },
            {
                breakpoint: 1036,
                settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '220px',
                    slidesToShow: 1,
                    dots: true
                }
            },
            {
                breakpoint: 726,
                settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '130px',
                    slidesToShow: 1,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '110px',
                    slidesToShow: 1,
                    dots: true
                }
            },
            {
                breakpoint: 530,
                settings: {
                    arrows: false,
                    centerMode: false,
                    centerPadding: '330px',
                    slidesToShow: 1,
                    dots: true
                }
            },
            {
                breakpoint: 376,
                settings: {
                    arrows: false,
                    centerMode: false,
                    centerPadding: '30px',
                    slidesToShow: 1,
                    dots: true
                }
            }
        ]
    });

    $('.slickslide__story').slick({
        centerMode: true,
        centerPadding: '300px',
        slidesToShow: 2,
        focusOnSelect: true,
        cssEase: 'linear',
        dots: true,
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 4000,
        adaptiveHeight: false,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    centerPadding: '250px',
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 1100,
                settings: {
                    centerPadding: '170px',
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 726,
                settings: {
                    centerPadding: '130px'
                }
            }
        ]
    });

    $('.slider_nav').slick();
    $('.sidebar__case__slider').slick();
    $('.c2a__slider').slick();

    // slider for facts
    $('.slider-for-facts').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        pauseOnHover: true,
        fade: true,
        asNavFor: '.slider-nav-facts',
        autoplaySpeed:3000,
        infinite: true,
        autoplay: true
    });
    $('.slider-nav-facts').slick({
        arrows: false,
        slidesToShow: 6,
        pauseOnHover: true,
        asNavFor: '.slider-for-facts',
        dots: false,
        centerMode: true,
        focusOnSelect: true,
        infinite: false,
        vertical: true
    });


    $('.slider-nav-facts').on('mouseenter', '.slick-slide', function (e) {
        var $currTarget = $(e.currentTarget),
            index = $currTarget.data('slick-index'),
            slickObj = $('.slider-for-facts').slick('getSlick');

        slickObj.slickGoTo(index);

    });

    $(".facts__holder").mouseover(function(){
        $('.slider-for-facts').slick('slickPause');
    });

    $(".facts__holder").mouseout(function(){
        $('.slider-for-facts').slick('slickPlay');
    });


    // slider for explain with info
    $('.slider-for-explain').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        pauseOnHover: true,
        fade: true,
        asNavFor: '.slider-nav-explain',
        autoplaySpeed:3000,
        infinite: true,
        autoplay: true,
        adaptiveHeight: true
    });
    $('.slider-nav-explain').slick({
        arrows: false,
        slidesToShow: 9,
        pauseOnHover: true,
        asNavFor: '.slider-for-explain',
        dots: false,
        centerMode: true,
        focusOnSelect: true,
        infinite: false,
        vertical: true
    });

    $('.slider-nav-explain').on('mouseenter', '.slick-slide', function (e) {
        var $currTarget = $(e.currentTarget),
            index = $currTarget.data('slick-index');

        $('.slider-for-explain').slick('slickGoTo', index, false);
    });

    $(".explain__holder").mouseover(function(){
        $('.slider-for-explain').slick('slickPause');
    });

    $(".explain__holder").mouseout(function(){
        $('.slider-for-explain').slick('slickPlay');
    });



    // prevent slick to have an delay and doesn't go to the current slide
    var pointersetTimeout = '';
    $('.block__holder').hover(function() {
        //$('.block__holder').css('pointer-events', 'none');
        element = $(this);
        clearTimeout(pointersetTimeout);
        var pointersetTimeout = setTimeout(() => {
            element.click();
        }, 100);
    });

    if ($(window).width() < 992) {
        $('.explainblock__holder .explain__block').click(function () {
            var thisElement = $(this);
            var offsetTop = thisElement.closest('.explain__holder').find('.explain_text_inner').offset().top - 100;
            $('html, body').animate({
                scrollTop: offsetTop
            }, 1000);
        });
    }

});
