var $ = jQuery.noConflict();

$(function() {
    if( $('#response').length ) {
        // on start of blog display them
        AOS.init();
        activateCategoryFromURL();
    }
    $('#filter, #sorteren, .filter__selectors').on('change', function() {
        const filter = $('#filter');
        const addingLocation = $('#response');
        const featuredRow = $('.courses__loop .featured');
        featuredRow.addClass('d-none');

        // Show skeleton loader
        const cardCount = addingLocation.find('.card__item').length;
        if(cardCount === 0) {
            addingLocation.html('');
        }

        // make the cards loading
        addingLocation.find('.card__item').addClass('card-is-loading');

        // fill the cards until 6 of it is lower than 6
        const totalCards = 6;
        if (cardCount <= totalCards) {
            for (let i = 0; i < (totalCards - cardCount); i++) {
                addingLocation.append(getSkeletonItem());
            }
        }

        // Get the new content depending on the filters. Contents is loaded by /classes/Qlic/Wordpress/Theme/Filter.php
        $.ajax({
            url: filter.attr('action'),
            data: filter.serialize(), // form data
            type: filter.attr('method'), // POST
            beforeSend: function(xhr) {
                filter.find('button').text('Processing...'); // changing the button label
            },
            success: function(data) {
                // Simulate delay for demonstration purposes
                setTimeout(function() {
                    addingLocation.html(data); // insert data
                    filter.find('button').text('Apply filter'); // changing the button label back

                    // Build URL querystring
                    const filterData = filter.serializeArray();
                    var containsNameWithBrackets = checkNameWithBrackets(filterData);
                    // don't show Totaal pakket when filter is used
                    if (containsNameWithBrackets) {
                        featuredRow.addClass('d-none');
                    } else {
                        featuredRow.removeClass('d-none');
                    }

                    // Check if filter has techblogs
                    var containsTechblogs = checkTechBlogs(filterData);
                    if(containsTechblogs) {
                        $('.techblogs-intro').css('display', 'none');
                    } else {
                        $('.techblogs-intro').css('display', 'block');
                    }


                    let queryString = '';
                    filterData.forEach(function(item) {
                        if (item.name !== 'action' && item.name !== 'filterUrl' && item.name !== 'sorteren') {
                            queryString += item.name + '=' + encodeURIComponent(item.value) + '&';
                        }
                    });

                    // Remove trailing '&' from the querystring
                    queryString = queryString.slice(0, -1);

                    // Append the querystring to the current URL
                    const currentURL = window.location.href;
                    const updatedURL = currentURL.split('?')[0] + '?' + queryString;
                    history.pushState(null, '', updatedURL);

                    activateCategoryFromURL();

                    AOS.init();

                }, 0); // Adjust the delay time according to your needs
            }
        });


        return false;
    });

    $('.allFilterCheckbox').on('click', function() {
        var $checkboxes = $(this).closest('.accordion__item-options').find(':checkbox');
        $checkboxes.prop('checked', this.checked);
    });

    // callapse items if filterbar doens't fit on screen
    $(window).on("load resize",function(e) {
        $('.filterbar__filter .accordion__item-head, .filterbar__filter .load__more').click(function () {
            checkMaxHeightFilter($(this));
        });
    });

    let minWidth = 0;
    $('.case__menu__holder .case__menu').each(function() {
        let thisWidth = $(this).outerWidth();
        var numericWidth = parseFloat(thisWidth);
        if(numericWidth > minWidth) {
            minWidth = numericWidth;
        }
        $(this).closest('.case__menu__holder').css('min-width', minWidth + 20);
        $(this).closest('.case__menu__holder').addClass('done');
        $(this).css('width', '100%');
    });

    setListeners();
});

function checkNameWithBrackets(arr) {
    var containsBracket = false;
    $.each(arr, function(index, item) {
        if (item.name.indexOf('[]') !== -1) {
            containsBracket = true;
            return false; // Stop de iteratie zodra een naam met haakjes is gevonden
        }
    });
    return containsBracket;
}

function activateCategoryFromURL() {
    // Haal de volledige URL op
    var url = window.location.href;
    // Split de URL bij "/"
    var parts = url.split("/");

// Zoek het deel dat na "/category/" komt (in dit geval, is het deel na index 5)
    var category = parts[5];
    $("input[type=checkbox][value='" + category + "']").prop("checked", true);
}

function checkTechBlogs(arr) {
    var containsTechBlogs = false;
    $.each(arr, function(index, item) {
        if (item.value.indexOf('techblogs') !== -1) {
            containsTechBlogs = true;
            return false; // Stop de iteratie zodra een naam met haakjes is gevonden
        }
    });
    return containsTechBlogs;
}

function generateRandomWord(minLength, maxLength) {
    var length = Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;
    var characters = 'abcdefghijklmnopqrstuvwxyz';
    var word = '';

    for (var i = 0; i < length; i++) {
        var randomIndex = Math.floor(Math.random() * characters.length);
        word += characters.charAt(randomIndex);
    }

    return word;
}

function checkMaxHeightFilter(clickedelement) {
    const checkMaxTimeOut = setTimeout(function() {
        const filterbar = $('.filterbar__filter'),
            filterbarHeight = filterbar.outerHeight(),
            headerHeight = $('#header-bar-wrapper').outerHeight(),
            bodyHeight = $(window).height(),
            marge = 0,
            maxHeight = bodyHeight - headerHeight - marge;

        if(filterbarHeight >= maxHeight) {
            $('.accordion__item-head.item-is-open').not(clickedelement).first().click();
            checkMaxHeightFilter(clickedelement);
        }
    }, 500);
}

function getSkeletonItem() {
    return '<div class="card__item card-is-loading col-md-12 col-md-6 col-lg-4 blogItem aos-init aos-animate" data-aos="fade"><div class="blog-category"><ul class="post-categories"><li><a rel="category tag">' +
        generateRandomWord(4, 10) +
        '</a></li></ul></div><a class="blog-item"><div class="item__image"><img /><div class="published__date-container"><div class="published__date"><div class="date__format"><span><i class="fal fa-calendar-alt mr-2" aria-hidden="true"></i>' +
        generateRandomWord(8, 12) +
        '</span><span><i class="fal fa-clock mr-2" aria-hidden="true"></i>' +
        generateRandomWord(8, 12) +
        '</span></div></div></div></div><div class="item__content"><h5>' +
        generateRandomWord(10, 25) +
        '</h5><p>' +
        generateRandomWord(10, 30) +
        '<br />' +
        generateRandomWord(10, 30) +
        '<br />' +
        generateRandomWord(10, 30) +
        '</p><div class="btn border">Lees verder</div></div></a></div>';
}

function setListeners() {
    let body = $('body');

    body.on('click', '.filter__toggle', (e) => {
        let filter_containers = $('.filter__container.open');
        let clickedObj = e.currentTarget.parentNode;
        if((clickedObj.classList.contains('open'))) {
            $(clickedObj).removeClass('open')
        } else {
            filter_containers.each((fc) => {
                if(filter_containers[fc] !== clickedObj) {
                    $(filter_containers[fc]).removeClass('open');
                }
            });
            $(clickedObj).addClass('open')
        }


    });

    body.on('click', (e) => {
        let filter_containers = $('.filter__container, .open');
        if(!(e.target.classList.contains('case__menu__header') || e.target.tagName === 'LABEL' || e.target.tagName === 'INPUT')) {
            filter_containers.each((fc) => {
                $(filter_containers[fc]).removeClass('open');
            });
        }
    });
}