$(document).ready(function () {

    var styles = [
        'background: #2db099',
        'color: white',
        'display: block',
        'font-size: 3em',
        'padding: 2em',
        'text-align: center',
    ].join(';');

    console.log('%c Wij zijn altijd opzoek naar Developers. Neem vooral een kijkje bij de vacatures! 🚀', styles);
    console.log('%c https://www.qlic.nl/werken-bij/', styles);

});
